import { render, staticRenderFns } from "./serveDetail.vue?vue&type=template&id=97873aaa&scoped=true&lang=pug&"
import script from "./serveDetail.vue?vue&type=script&lang=js&"
export * from "./serveDetail.vue?vue&type=script&lang=js&"
import style0 from "./serveDetail.vue?vue&type=style&index=0&id=97873aaa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97873aaa",
  null
  
)

export default component.exports